export const solutionTypeFormatter = (item) => {
  switch (item.alias) {
    case 'curso_ead':
      return 'Curso EAD'

    case 'dicas_whatsapp':
      return 'Curso WhatsApp'

    case 'videoconferencia':
      return 'Curso Ao Vivo'

    case 'presencial':
      return 'Presencial'

    case 'e-book':
      return 'E-Book'

    case 'video':
      return 'Video'

    case 'webinar':
      return 'Webinar'

    case 'podcast':
      return 'Podcast'

    case 'whatsapp_watson':
      return 'Curso WhatsApp - Watson'

    case 'external_activity':
      return 'Atividade Externa'

    case 'curso_ead_webaula':
      return 'Curso EAD - Webaula'

    case 'curso_ead_moodle':
      return 'Curso EAD - Moodle'

    default:
      return item.name
  }
}
